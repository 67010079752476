<!--
 * @Description: 设备接入流程概览
 * @Author: kecraft
 * @Date: 2024-01-29 16:00:20
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-31 11:02:16
 * @FilePath: /impact-iotos-console/src/components/flow/deviceFlow.vue
-->
<template>
  <div class="device-flow">
    <div class="top-container">
      <div>接入流程概览</div>
      <div class="arrow-btn">
        <div @click="handleFlowChange">
          {{ isShow ? "隐藏" : "显示" }}指引<el-icon style="margin-left: 20px;">
            <ArrowUp v-if="isShow" />
            <ArrowDown v-if="!isShow" />
          </el-icon>
        </div>
      </div>
    </div>
    <div style="width:80vw" v-if="isShow">
      <el-scrollbar style="width: 100%" wrap-style="overflow-y:hidden;">
        <div class="main-container">
          <div class="main-item" v-for="(item, index) of flowList" :key="index">
            <div class="item-left">{{ item.sort }}</div>
            <div class="item-right">
              <div class="item-title">
                <div>{{ item.title }}</div>
                <div class="link-title" @click="handleGo(item.link)">查看文档<el-icon style="margin-left: 5px;">
                    <Right />
                  </el-icon></div>
              </div>
              <div class="item-container">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>
<script setup>
import { ArrowUp, ArrowDown, Right } from '@element-plus/icons-vue';
import { ref } from 'vue';
const isShow = ref(false);
const flowList = ref([
  {
    sort: "01",
    title: "创建产品",
    content: "产品是同品类设备的集合",
    link: "http://10.10.49.179:3824/web/#/632016434/191201022",
  },
  {
    sort: "02",
    title: "创建设备",
    content: "创建设备获取连接平台所需的身份信息",
    link: "http://10.10.49.179:3824/web/#/632016434/191201015",
  },
  {
    sort: "03",
    title: "功能定义",
    content: "产品下的设备都会继承产品的功能定义",
    link: "http://10.10.49.179:3824/web/#/632016434/191201010",
  },
  {
    sort: "04",
    title: "设备接入",
    content: "将设备通过MQTT、HTTP等协议接入平台",
    link: "http://10.10.49.179:3824/web/#/632016434/191201018",
  },
  {
    sort: "05",
    title: "查看上报数据",
    content: "查看设备上报的属性数据",
    link: "http://10.10.49.179:3824/web/#/632016434/191201021",
  }
])

const handleFlowChange = () => {
  isShow.value = !isShow.value
}
const handleGo = (link) => {
  const a = document.createElement('a')
  a.target = '_blank'
  a.href = link
  a.click()
}
</script>

<style lang="less" scoped>
.device-flow {
  box-sizing: border-box;
  padding: 0 46px;
  // margin-bottom: 20px;
  border-bottom: 1px solid #E9ECF0;
}

.top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  box-sizing: border-box;
  padding: 20px 0;

  .arrow-btn {
    display: flex;
    align-items: center;
    flex: 1;
    color: #888;
    justify-content: flex-end;
    cursor: pointer;
  }

  .arrow-btn:hover,
  .arrow-btn:active {
    color: #555;
  }

}

.main-container {
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  align-items: center;

  .main-item {
    cursor: default;
    box-sizing: border-box;
    padding: 20px;
    width: 280px;
    height: 100px;
    border: 1px solid #e3e3e3;
    margin-right: 10px;
    display: flex;
    background: #fff;

    .item-left {
      font-size: 24px;
      font-weight: bold;
      margin-right: 10px;
      color: #015ee0;
    }

    .item-right {
      flex: 1;
    }

    .item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 14px;
      flex: 1;

      &>div {
        font-size: 14px;
      }

      &>div:last-child {
        display: flex;
        align-items: center;
        color: #015ee0;
      }
    }

    .item-container {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .link-title {
    display: none !important;
  }

  .main-item:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .21);

    .link-title {
      cursor: pointer;
      display: block !important;
    }
  }
}
</style>
